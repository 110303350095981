export interface iContentWrapper {
  children: (data: any) => void
}

export enum AboutGDMCardType {
  Heading,
  Content,
}

export interface iAboutGDMCard {
  type: AboutGDMCardType
  text: object
  image?: any
  textDirection?: string
  bgColor?: string
}
