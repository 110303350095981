import React from "react"
import { FaFlask, FaRegLightbulb } from "react-icons/fa"

import { AboutGDMCardType, iAboutGDMCard } from "./types"

export const makeContentData = (imageDataFromQuery: any): iAboutGDMCard[] => {
  return [
    {
      type: AboutGDMCardType.Heading,
      text: (
        <span>
          <span className="text-base inline-block bg-white text-site-pink rounded-full px-3 py-1 mb-4">
            <FaRegLightbulb className="inline-block mb-1 mr-1" />
            <span>Our Inspiration</span>
          </span>
          <br />
          Why consider evaluating risk of GDM?
        </span>
      ),
      textDirection: "sm:text-right",
      bgColor: "bg-site-pink",
    },
    {
      type: AboutGDMCardType.Content,
      text: (
        <span>
          Up to{" "}
          <strong className="text-site-pink">30% in high risk groups</strong>{" "}
          and has{" "}
          <strong className="text-site-pink">increased nearly 6x</strong> since
          1980.
        </span>
      ),
      image: imageDataFromQuery.statsIllustration.publicURL,
    },
    {
      type: AboutGDMCardType.Content,
      text: (
        <span>
          It causes&nbsp;
          <strong className="text-site-pink">
            risks for both the mother and the baby
          </strong>
          , but consequences can often be controlled with dietary modification
          and/or insulin therapy.
        </span>
      ),
      image: imageDataFromQuery.motherIllustration.publicURL,
    },
    {
      type: AboutGDMCardType.Content,
      text: (
        <span>
          The patients can be
          <strong className="text-site-pink">
            &nbsp;diagnosed by one single test&nbsp;
          </strong>
          - but disease severity is variable, not all patients require the same
          level of care.
        </span>
      ),
      image: imageDataFromQuery.doctorIllustration.publicURL,
    },
    {
      type: AboutGDMCardType.Content,
      text: (
        <span>
          Costs in a pregnancy can go up to
          <strong className="text-site-pink">
            &nbsp;25% higher (avg ~ AU$10,500 per birth)&nbsp;
          </strong>
          than normal.
        </span>
      ),
      image: imageDataFromQuery.walletIllustration.publicURL,
    },
    {
      type: AboutGDMCardType.Heading,
      text: (
        <span>
          <span className="text-base inline-block bg-white text-blue-900 rounded-full px-3 py-1 mb-4">
            <FaFlask className="inline-block mb-1 mr-1" />
            <span>Our Solution</span>
          </span>
          <br />A web-based, data-driven prediction tool
        </span>
      ),
      textDirection: "sm:text-left",
      bgColor: "site-bg-blue",
    },
    {
      type: AboutGDMCardType.Content,
      text: (
        <span>
          A web tool
          <strong>
            &nbsp;accessible to all levels of technological literacy
          </strong>
          , on as many devices as possible.
        </span>
      ),
      image: imageDataFromQuery.devicesIllustration.publicURL,
    },
    {
      type: AboutGDMCardType.Content,
      text: (
        <span>
          Returns an <strong>individualised estimate of risk</strong>, and
          allows communication of risk in simple language and visualisation.
        </span>
      ),
      image: imageDataFromQuery.predictIllustration.publicURL,
    },
    {
      type: AboutGDMCardType.Content,
      text: (
        <span>
          Allows greater capacity for <strong>shared decision making</strong> in
          the clinic,
          <strong> empowering women</strong> by helping them be better informed.
        </span>
      ),
      image: imageDataFromQuery.decisionIllustration.publicURL,
    },
  ]
}

export default makeContentData
