import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import SiteContainer from "../../SiteContainer"
import { externalLinks } from "../../urls"

const query = graphql`
  query {
    allDataYaml {
      nodes {
        indexPage {
          heroSection {
            content
            heroImage: image {
              childImageSharp {
                fluid(maxWidth: 1440) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
            subtitle
            title
          }
        }
      }
    }
  }
`

const HeroSection = () => {
  return (
    <StaticQuery query={query}>
      {data => {
        const heroSectionData = data.allDataYaml.nodes[0].indexPage.heroSection
        return (
          <section className="grid grid-cols-1 lg:grid-cols-2">
            <SiteContainer className="h-full bg-gray-100 py-12 site-text-blue  hidden lg:flex items-center ">
              <div>
                <p>{heroSectionData.subtitle}</p>
                <h1 className="site-text-blue text-5xl">
                  {heroSectionData.title}
                </h1>
                <div className="py-2" />
                <p>{heroSectionData.content}</p>
              </div>
            </SiteContainer>
            <div className="h-full">
              <Img
                className="hidden lg:block h-full w-full"
                fluid={heroSectionData.heroImage.childImageSharp.fluid}
              />
              <Img
                className="hidden sm:block lg:hidden h-full w-full"
                style={{ height: "300px" }}
                fluid={heroSectionData.heroImage.childImageSharp.fluid}
              />
              <Img
                className="block sm:hidden h-full w-full"
                style={{ height: "236px" }}
                fluid={heroSectionData.heroImage.childImageSharp.fluid}
              />
            </div>
            <div className="block md:hidden grid grid-cols-2 text-center text-white">
              <a
                href={externalLinks.lifestyleApp}
                referrerPolicy={"no-referrer"}
              >
                <div className="hover:bg-green-500 bg-green-600 py-2 transition duration-100">
                  <p>Lifestyle</p>
                </div>
              </a>
              <a
                href={externalLinks.outcomesApp}
                referrerPolicy={"no-referrer"}
              >
                <div className="site-bg-blue py-2 hover:bg-blue-900 transition duration-100">
                  <p>Outcomes</p>
                </div>
              </a>
            </div>
            <SiteContainer className="h-full bg-gray-100 py-12 px-4 sm:px-12 site-text-blue block lg:hidden items-center ">
              <div className="text-center">
                <p>{heroSectionData.subtitle}</p>
                <h1 className="site-text-blue text-5xl">Personal GDM</h1>
                <div className="py-2" />
                <p>{heroSectionData.content}</p>
              </div>
            </SiteContainer>
          </section>
        )
      }}
    </StaticQuery>
  )
}

export default HeroSection

/*
*
* (

      )
* */
