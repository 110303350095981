import React from "react"
import { FaArrowRight } from "react-icons/fa"

import { DarkAppButton, BlueAppButton } from "../../../AppButton/buttons"

interface iCard {
  id: string
  title: string
  subtitle: string
  backgroundColor: string
  text: string
  link: string
}

const Card: React.FC<iCard> = ({
  id,
  title,
  subtitle,
  text,
  backgroundColor,
  link,
}) => {
  return (
    <div className="site-border-radius overflow-hidden border hover:shadow transition duration-100 ease-in-out">
      <a href={link} referrerPolicy={"no-referrer"}>
        <div className={`text-center ${backgroundColor} text-white py-8 px-4`}>
          <p className="text-sm">{subtitle}</p>
          <h1 className="text-white text-3xl">{title}</h1>
        </div>
      </a>
      <div className="p-4 text-gray-600">
        <p className="text-sm mb-4">{text}</p>
        <div className="flex items-start justify-end">
          <a href={link} referrerPolicy={"no-referrer"}>
            <BlueAppButton
              title="Explore"
              Icon={<FaArrowRight />}
              iconOnRight={true}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Card
