import React from "react"

const Tag: React.FC<{ text: string }> = ({ text }) => {
  return (
    <p className="bg-gray-200 text-blue-900 py-1 px-3 text-xs rounded inline-block">
      {text}
    </p>
  )
}

export default Tag
