import React from "react"

interface iHeading {
  text: string
  Icon?: any
  textColor?: string
}

const Heading: React.FC<iHeading> = ({ text, Icon, textColor }) => {
  return (
    <h1
      className={`flex justify-center md:justify-start items-center ${
        textColor || "site-text-blue"
      }  text-4xl`}
    >
      {Boolean(Icon) && <span className="mr-3">{Icon}</span>}
      <span>{text}</span>
    </h1>
  )
}

export default Heading
