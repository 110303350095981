import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

const query = graphql`
  query {
    bgImage: file(relativePath: { eq: "stake-holders-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Heading = () => (
  <span>
    <span className="text-2xl">Impact of our solution</span>
    <br />
    Personal GDM
  </span>
)
const Content = () => (
  <>
    These tools have the potential to reduce costs associated with greater than
    necessary care at maternity services.
    <br />
    <br />
    Enable the risk-stratified care - minimising the greater than necessary
    care given to patients who may be of lower risk levels, save costs for
    patients and facilities.
    <br />
    <br />
    Help reduce a significant burden on the health-care system by helping
    better allocate resources.
  </>
)

const SolutionImpactSection = () => {
  return (
    <section className="grid grid-cols-1 lg:grid-cols-2">
      <div>
        <StaticQuery query={query}>
          {data => (
            <>
              <Img
                className="hidden lg:block h-full w-full"
                fluid={data.bgImage.childImageSharp.fluid}
              />
              <Img
                className="hidden sm:block lg:hidden h-full w-full"
                style={{ height: "300px" }}
                fluid={data.bgImage.childImageSharp.fluid}
              />
              <Img
                className="block sm:hidden h-full w-full"
                style={{ height: "236px" }}
                fluid={data.bgImage.childImageSharp.fluid}
              />
            </>
          )}
        </StaticQuery>
      </div>
      <div className="site-bg-blue">
        <div className="flex items-center px-6 sm:pl-12 sm:pr-12 xl:pr-16 py-8 text-center lg:text-left lg:py-16 min-h-full bg-site-blue w-full text-white">
          <div>
            <h1 className="text-4xl sm:text-5xl text-white leading-none">
              <Heading />
            </h1>
            <p className="mt-5 mb-2">
              <Content />
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SolutionImpactSection
