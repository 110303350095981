import React from "react"
import { FaScroll, FaExternalLinkAlt } from "react-icons/fa"
import { DarkAppButton } from "../AppButton/buttons"
import SiteParagraph from "./SiteParagraph"
import Tag from "./Tag"
import CardWrapper from "./CardWrapper"
import CardContentWrapper from "./CardContentWrapper"

export interface iPublicationCard {
  title: string
  link: string
  authors: string[]
}

const PublicationCardView: React.FC<iPublicationCard> = ({
                                                           title,
                                                           link,
                                                           authors,
                                                         }) => {
  return (
    <CardWrapper className="overflow-hidden py-4">
      <CardContentWrapper>
        <SiteParagraph className=" text-xl relative border-b">
          <span
            className="absolute"
            style={{ marginLeft: "-0px", marginTop: "-8px" }}
          >
            <FaScroll className="h-full text-6xl text-gray-300" />
          </span>
          <span className="relative">{title}</span>
        </SiteParagraph>

        <div className="my-1" />
        <SiteParagraph className="font-bold text-sm">Authors</SiteParagraph>
        <div className="my-1" />
        <div className="flex flex-wrap">
          {authors.map((item: string, key: number) => (
            <div key={key} className="mb-1 mr-1">
              <Tag text={item} key={key} />
            </div>
          ))}
        </div>
        <div className="my-2" />
        <div className="flex justify-end">
          <a href={link} target="__blank">
            <DarkAppButton title="Know more" Icon={<FaExternalLinkAlt />} />
          </a>
        </div>
      </CardContentWrapper>
    </CardWrapper>
  )
}

const PublicationCard: React.FC<iPublicationCard> = props => {
  return <PublicationCardView {...props} />
}

export default PublicationCard
