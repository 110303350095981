import React from "react"

const CardWrapper: React.FC<{
  children: object
  className?: string
  flexRow?: Boolean
  rounded?: Boolean
}> = ({ children, className, flexRow = false, rounded = true }) => (
  <div
    className={[
      "bg-white border hover:shadow transition duration-100",
      !flexRow ? "flex flex-col" : "",
      rounded ? "site-border-radius" : "rounded",
      className,
    ].join(" ")}
  >
    {children}
  </div>
)

export default CardWrapper
