import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { FaFlask, FaRegLightbulb } from "react-icons/fa"

import { AboutGDMCardType, iAboutGDMCard, iContentWrapper } from "./types"

const query = graphql`
  query {
    statsIllustration: file(relativePath: { eq: "stats-illustration.svg" }) {
      publicURL
    }

    motherIllustration: file(relativePath: { eq: "mother-illustration.svg" }) {
      publicURL
    }

    doctorIllustration: file(relativePath: { eq: "doctor-illustration.svg" }) {
      publicURL
    }

    walletIllustration: file(relativePath: { eq: "wallet-illustration.svg" }) {
      publicURL
    }

    devicesIllustration: file(
      relativePath: { eq: "devices-illustration.svg" }
    ) {
      publicURL
    }

    predictIllustration: file(
      relativePath: { eq: "predict-illustration.svg" }
    ) {
      publicURL
    }

    decisionIllustration: file(
      relativePath: { eq: "decision-illustration.svg" }
    ) {
      publicURL
    }
  }
`

const ContentImagesWrapper: React.FC<iContentWrapper> = ({ children }) => {
  return (
    <StaticQuery query={query}>
      {data => {
        return <>{children(data)}</>
      }}
    </StaticQuery>
  )
}

export default ContentImagesWrapper
