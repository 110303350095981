import React from "react"

export const CardWrapper: React.FC<{
  children: object
  className?: string
  flexRow?: Boolean
  rounded?: Boolean
}> = ({ children, className, flexRow = false, rounded = true }) => (
  <div
    className={[
      "bg-white border hover:shadow transition duration-100",
      !flexRow ? "flex flex-col" : "",
      rounded ? "site-border-radius" : "rounded",
      className,
    ].join(" ")}
  >
    {children}
  </div>
)

export const CardContentWrapper: React.FC<{ children: object }> = ({
  children,
}) => (
  <div className="text-site-blue-dark relative flex flex-1 flex-col p-4">
    {children}
  </div>
)

export const ContentCardWrapper: React.FC<{
  children: any
  className?: string
}> = ({ children, className }) => {
  return (
    <CardWrapper className={className}>
      <CardContentWrapper>{children}</CardContentWrapper>
    </CardWrapper>
  )
}
