import Img from "gatsby-image"
import React from "react"
import { FaArrowRight } from "react-icons/fa"

import { BlueAppButton } from "../../../AppButton/buttons"

interface iCard {
  fluidImages: {
    cover: any
    logo: any
  }
  link: string
  title: string
  text: string
  id: string
}

const Card: React.FC<iCard> = ({ fluidImages, link, text, title, id }) => {
  return (
    <div className="border site-border-radius bg-white flex flex-col hover:shadow transition duration-100 ease-in overflow-hidden h-full">
      <a
        href={link}
        referrerPolicy="no-referrer"
        target="_blank"
        className="no-underline"
      >
        <div className="relative site-border-radius">
          <Img
            style={{ height: "200px" }}
            fluid={fluidImages.cover.childImageSharp.fluid}
          />
          <div className="absolute bottom-0 left-0 bg-white py-4 px-6 site-border-radius-tr border-b">
            <Img
              style={{ width: id === "mchri" ? "60px" : "100px" }}
              fluid={fluidImages.logo.childImageSharp.fluid}
            />
          </div>
        </div>
      </a>

      <div className="p-4 flex flex-col justify-between flex-1">
        <div>
          <h1 className="site-text-blue text-xl mb-2 leading-none">{title}</h1>
          <p className="text-sm text-gray-600 mb-3">
            {text.slice(0, 100)}
            {text.length > 100 && "..."}
          </p>
        </div>

        <div className="flex items-center justify-end">
          <a
            href={link}
            referrerPolicy="no-referrer"
            target="_blank"
            className="no-underline"
          >
            <BlueAppButton
              title="Know more"
              Icon={<FaArrowRight />}
              iconOnRight={true}
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Card
