import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { AiOutlineAppstoreAdd } from "react-icons/ai"

import Heading from "../../../Heading"
import SiteContainer from "../../../SiteContainer"
import Card from "./Card"

const query = graphql`
  query {
    allDataYaml {
      nodes {
        indexPage {
          applicationsSection {
            backgroundColor
            id
            subtitle
            title
            text
            link
          }
        }
      }
    }
  }
`

type ApplicationsSectionData = {
  id: string
  title: string
  subtitle: string
  backgroundColor: string
  text: string
  link: string
}

const ApplicationsSection = () => {
  return (
    <SiteContainer className="py-8">
      <Heading text={"Applications"} Icon={<AiOutlineAppstoreAdd />} />
      <div className="py-4" />
      <StaticQuery query={query}>
        {data => {
          const applicationsSection = data.allDataYaml.nodes[0].indexPage
            .applicationsSection as ApplicationsSectionData[]
          console.log(applicationsSection)
          return (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {applicationsSection.map((item, index) => (
                <Card {...item} key={index} />
              ))}
            </div>
          )
        }}
      </StaticQuery>
    </SiteContainer>
  )
}

export default ApplicationsSection
