import React from "react"

import SiteParagraph from "../../../../utils/SiteParagraph"
import { ContentCardWrapper } from "./utils"

const Content: React.FC<{ text: object; image?: any }> = ({ text, image }) => {
  return (
    <div>
      <ContentCardWrapper className="h-full hover:shadow-none flex items-center">
        <div className="h-full flex items-center">
          <div>
            <div className="flex justify-center">
              <img style={{ width: "200px", height: "140px" }} src={image} />
            </div>
            <SiteParagraph className="text-center">{text}</SiteParagraph>
          </div>
        </div>
      </ContentCardWrapper>
    </div>
  )
}

export default Content
