import React from "react"

import SiteContainer from "../SiteContainer"
import SiteHeading from "./SiteHeading"

export interface iContentSectionWrapper {
  children?: object
  title: string
  Icon?: object
}

export const ContentSectionTitle: React.FC<{ text: string; Icon?: object }> = ({
  text,
  Icon,
}) => (
  <div>
    <SiteHeading className="mb-4 text-4xl sm:text-5xl flex items-center justify-center sm:justify-start">
      {Icon}
      {Icon ? <div className="mx-2" /> : <React.Fragment />}
      {text}
    </SiteHeading>
  </div>
)

export const ContentSectionWrapper: React.FC<iContentSectionWrapper> = ({
  children,
  title,
  Icon,
}) => (
  <section>
    <SiteContainer>
      <ContentSectionTitle text={title} Icon={Icon} />
      <div>{children}</div>
    </SiteContainer>
  </section>
)
