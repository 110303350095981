import { graphql, StaticQuery } from "gatsby"
import React from "react"
import { FiUsers } from "react-icons/fi"

import Heading from "../../../Heading"
import SiteContainer from "../../../SiteContainer"
import Card from "./Card"

type poweredBySectionData = {
  fluidImages: {
    cover: any
    logo: any
  }
  link: string
  title: string
  text: string
  id: string
}

const query = graphql`
  query MyQuery {
    allDataYaml {
      nodes {
        indexPage {
          poweredBySection {
            fluidImages {
              cover {
                childImageSharp {
                  fluid(maxWidth: 1440) {
                    aspectRatio
                    base64
                    sizes
                    src
                    srcSet
                  }
                }
              }

              logo {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    aspectRatio
                    base64
                    sizes
                    src
                    srcSet
                  }
                }
              }
            }
            link
            text
            title
            id
          }
        }
      }
    }
  }
`

const PoweredBySection = () => {
  return (
    <SiteContainer className="py-8 pb-12">
      <Heading text="Powered By" Icon={<FiUsers />} />
      <div className="py-4" />
      <StaticQuery query={query}>
        {data => {
          const poweredBySection = data.allDataYaml.nodes[0].indexPage
            .poweredBySection as poweredBySectionData[]
          return (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {poweredBySection.map((item, index) => {
                return <Card {...item} key={index} />
              })}
            </div>
          )
        }}
      </StaticQuery>
    </SiteContainer>
  )
}

export default PoweredBySection
