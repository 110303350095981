import React from "react"

import AboutSection from "./AboutSection"
import ApplicationsSection from "./ApplicationsSection"
import HeroSection from "./HeroSection"
import PoweredBySection from "./PoweredBySection"
import SolutionImpactSection from "./SolutionImpactSection"
import RelatedPublicationsSection from "./RelatedPublicationsSection"

interface iIndexPageView {}

const IndexPageView: React.FC<iIndexPageView> = () => {
  return (
    <section>
      <HeroSection />
      <ApplicationsSection />
      <AboutSection />
      <SolutionImpactSection />
      <RelatedPublicationsSection/>
      <PoweredBySection />
    </section>
  )
}

export default IndexPageView
