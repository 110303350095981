import * as React from "react"

import IndexPage from "../components/PageComponents/IndexPage"
import Layout from "../components/layout"
// @ts-ignore
import SEO from "../components/seo"

const Index = () => (
  <Layout>
    <SEO title="Home" />
    <IndexPage />
  </Layout>
)

export default Index
