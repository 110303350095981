import React from "react"

import SiteParagraph from "../../../../utils/SiteParagraph"
import { ContentCardWrapper } from "./utils"

const Heading: React.FC<{
  text: object
  textDirection?: string
  bgColor?: string
}> = ({ text, textDirection, bgColor }) => {
  return (
    <div>
      <ContentCardWrapper
        className={`${
          bgColor || "bg-site-blue"
        } h-full border-none hover:shadow-none`}
      >
        <div className="h-full  flex items-center justify-center py-6 lg:py-2">
          <SiteParagraph
            colorClassName="text-white"
            className={`px-2 text-center ${textDirection} font-bold`}
          >
            <span className=" text-3xl ">{text}</span>
          </SiteParagraph>
        </div>
      </ContentCardWrapper>
    </div>
  )
}

export default Heading
