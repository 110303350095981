import React from "react"
import { IoChatboxOutline } from "react-icons/io5"

import Heading from "../../../Heading"
import SiteContainer from "../../../SiteContainer"
import ContentController from "./ContentController"

const AboutSection = () => {
  return (
    <SiteContainer className="pb-8">
      <Heading text={"About Us"} Icon={<IoChatboxOutline />} />
      <div className="py-4" />
      <ContentController />
    </SiteContainer>
  )
}

export default AboutSection
