import React from "react"
import SiteParagraph from "../../utils/SiteParagraph"
import SiteHeading from "../../utils/SiteHeading"
import PublicationCard, { iPublicationCard } from "../../utils/PublicationCard"
import { ContentSectionWrapper } from "../../utils"

const publicationsData: iPublicationCard[] = [
  {
    title:
      "Prediction for Risk-Stratified Care for Women with Gestational Diabetes",
    link: "https://doi.org/10.2337/db20-168-LB",
    authors: ["Shamil Cooray et al"],
  },
]

const RelatedPublicationsSectionView = () => {
  return (
    <div className="py-8 border-b">
      <ContentSectionWrapper title="">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="h-full flex items-center sm:col-span-2">
            <div className="text-center lg:text-left">
              <SiteParagraph>Powered by MCHRI</SiteParagraph>
              <SiteHeading><span className="site-text-blue">Research</span></SiteHeading>
              <div className="py-2" />
              <SiteParagraph className="text-gray-600">
                These evidence based tools are designed to be used by healthcare providers together with pregnant women 
                who are or may be affected by gestational diabetes. They are used to calculate the probability of 
                (1) developing gestational diabetes, (2) the risk of an individual woman affected by gestational 
                diabetes to experience an adverse pregnancy outcome and (3) the combined risk of developing gestational 
                diabetes, hypertension or both.
              </SiteParagraph>
            </div>
          </div>
          {publicationsData.map((item, key) => (
            <div key={key}>
              <PublicationCard {...item} key={key} />
            </div>
          ))}
        </div>
      </ContentSectionWrapper>
    </div>
  )
}

const RelatedPublicationsSection = () => {
  return <RelatedPublicationsSectionView />
}

export default RelatedPublicationsSection
