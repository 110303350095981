import React from "react"

import ContentCard from "./ContentCard"
import ContentImagesWrapper from "./ContentImagesWrapper"
import makeContentData from "./makeContentData"

const ContentController = () => {
  return (
    <ContentImagesWrapper>
      {(data: any) => (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {makeContentData(data).map((item, index) => (
            <ContentCard {...item} key={index} />
          ))}
        </div>
      )}
    </ContentImagesWrapper>
  )
}

export default ContentController
