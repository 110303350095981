import React from "react"

import { AboutGDMCardType, iAboutGDMCard } from "../types"
import Content from "./Content"
import Heading from "./Heading"

const ContentCard: React.FC<iAboutGDMCard> = ({
  type,
  text,
  image,
  textDirection,
  bgColor,
}) => {
  if (type === AboutGDMCardType.Heading)
    return (
      <Heading text={text} textDirection={textDirection} bgColor={bgColor} />
    )
  if (type === AboutGDMCardType.Content)
    return <Content text={text} image={image} />
  return null
}

export default ContentCard
